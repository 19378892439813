import { IAppState, initialState, IScenario, scenarioKey, setupComputedStateValues } from "./context";

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export enum Types {
  Update   = 'UPDATE',
  Update_Scenario = 'UPDATE_SCENARIO',
  Reset    = 'RESET',
}

type AppPayload = {
  [Types.Update] : IAppState;
  [Types.Update_Scenario] : {scenario: IScenario, key: scenarioKey};
  [Types.Reset]    : IAppState;
}

type tScenarioKey = 'a' |'b' | 'c'

export type AppActions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>];

const buyRate = 2.40 / 100;
const buyFlatRate = 0.05;
const commissionMRRMultiplier = 2;
const commissionGTVBP = 10;
const commissionGTVMultiplier = commissionGTVBP / 10000;
const contractorMultiplier = 2;
const isContractorApprovalOffset = 1.00;

export const numberWithCommas = (x: number) => {
  if (x) {
    return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return 0;
  }
}

export const calcGTV = (state: IAppState) => {
  const totalGTV = state.averageTicket * state.weeklyCustomers * 52;
  const creditPercentageGTV = Math.round(totalGTV *  (state.creditPercentage * .01));
  return creditPercentageGTV
}

export const monthlySales = (state: IAppState) => {
  return calcGTV(state) / 12;
}

export const buyAmount = (state: IAppState) => {
  return (monthlySales(state) * buyRate) + (state.weeklyCustomers * buyFlatRate);
}

export const takeAmount = (state: IAppState, scenarioKey: tScenarioKey) => {
  const scenario = state[scenarioKey];
  return (monthlySales(state) * (scenario.percentageRate / 100)) + (state.weeklyCustomers * scenario.flatRate);
}

export const monthlyProfit = (state: IAppState, scenarioKey: tScenarioKey) => {
  const scenario = state[scenarioKey];
  return takeAmount(state, scenarioKey) - buyAmount(state) + scenario.monthlyFee;
}

export const getCalcString = (state: IAppState, scenarioKey: tScenarioKey) => {
  const scenario = state[scenarioKey];
  const gtvString = `GTV = Average Ticket ($${numberWithCommas(state.averageTicket)}) * Weekly Customers (${state.weeklyCustomers}) * 52 weeks in a year = $${numberWithCommas(calcGTV(state))}`;
  const monthlySalesString = `Monthly Sales = GTV ($${numberWithCommas(calcGTV(state))}) / 12 months in a year  = $${numberWithCommas(monthlySales(state))}`;
  const buyAmountString = `Our Buy Amount = (Monthly Sales ($${numberWithCommas(monthlySales(state))}) * Our Buy Rate (${buyRate * 100}%)) + (Weekly Customers (${(state.weeklyCustomers)}) * Our Buy Flat Rate ($${buyFlatRate})) = $${numberWithCommas(buyAmount(state))}`;
  const takeAmountString = `Our Take Amount = (Monthly Sales ($${numberWithCommas(monthlySales(state))}) * Partner Percentage Rate (${scenario.percentageRate}%)) + (Weekly Customers (${state.weeklyCustomers}) * Partner Flat Rate ($${scenario.flatRate})) = $${numberWithCommas(takeAmount(state, scenarioKey))}`;
  const monthlyRevenueString = `Our Monthly Revenue = Our Take Amount ($${numberWithCommas(takeAmount(state, scenarioKey))}) - Our Buy Amount ($${numberWithCommas(buyAmount(state))}) + Partner Monthly Fee ($${numberWithCommas(scenario.monthlyFee)}) = $${numberWithCommas(monthlyProfit(state, scenarioKey))}`;
  let paybackPeriodString;
  if (scenario.paybackPeriod) {
    paybackPeriodString = `Our Payback Period = Our Hardware Cost ($${numberWithCommas(scenario.hardwareCost)}) + Your Upfront Commission ($${numberWithCommas(calcCommissions(state, scenarioKey).upfront)}) / Our Monthly Gross Profit ($${numberWithCommas(monthlyProfit(state, scenarioKey))}) = Our Payback Period in Months (${scenario.paybackPeriod.toFixed(2)})`;
  }
  const calculationString = `${gtvString}\n${monthlySalesString}\n${buyAmountString}\n${takeAmountString}\n${monthlyRevenueString}\n${paybackPeriodString ? paybackPeriodString : ''}`;
  return calculationString;
}

export const calcPaybackPeriod = (state: IAppState, scenarioKey: tScenarioKey) => {
  const scenario = state[scenarioKey];
  const cost = scenario.hardwareCost + calcCommissions(state, scenarioKey).upfront;
  const payBackPeriodInMonths = cost / monthlyProfit(state, scenarioKey);
  return payBackPeriodInMonths;
}

export const calcResultColor = (state: IAppState, scenarioKey: tScenarioKey) => {
  const paybackPeriodInMonths = calcPaybackPeriod(state, scenarioKey);
  let greenNum = 3.00;
  let yellowNum = 3.01;
  let redNum = 5.00;
  if (state.isContractor) {
    greenNum = greenNum + isContractorApprovalOffset;
    yellowNum = yellowNum + isContractorApprovalOffset;
    redNum = redNum + isContractorApprovalOffset;
  }
  if (paybackPeriodInMonths <= greenNum && paybackPeriodInMonths >= 0.00) {
    return 'success'
  } else if (paybackPeriodInMonths >= yellowNum && paybackPeriodInMonths <= redNum) {
    return 'warning'
  } else {
    return 'danger'
  }
}

export const calcCommissions = (state: IAppState, scenarioKey: tScenarioKey) => {
  const scenario = state[scenarioKey];
  let monthlyFeeCommission = Math.round(scenario.monthlyFee * commissionMRRMultiplier);
  let gtvCommission = Math.round(calcGTV(state) * commissionGTVMultiplier);
  if (state.isContractor) {
    monthlyFeeCommission = Math.round(monthlyFeeCommission * contractorMultiplier);
    gtvCommission = Math.round(gtvCommission * contractorMultiplier);
  }
  return {
    monthlyFeeCommission,
    gtvCommission,
    total: monthlyFeeCommission + gtvCommission,
    upfront: monthlyFeeCommission + (gtvCommission / 2)
  };
}

export const appReducer = (state: IAppState, action: AppActions) => {
  let newStateBeforeComputedProperties;
  let newState;
  switch (action.type) {
    case Types.Update:
      newStateBeforeComputedProperties = {
        ...state,
        ...action.payload,
      }
      newState = setupComputedStateValues(newStateBeforeComputedProperties);
      localStorage.setItem('state', JSON.stringify(newState));
      return newState;
    case Types.Update_Scenario:
      const key = action.payload.key;
      newStateBeforeComputedProperties = {
        ...state,
        [key]: {
          ...action.payload.scenario,
        }
      }
      newState = setupComputedStateValues(newStateBeforeComputedProperties);
      localStorage.setItem('state', JSON.stringify(newState));
      return newState;
    case Types.Reset:
      newState = setupComputedStateValues(initialState);
      localStorage.setItem('state', JSON.stringify(newState));
      return newState;
    default:
      localStorage.setItem('state', JSON.stringify(newState));
      return state;
  }
}