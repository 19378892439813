import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useContext } from 'react';
import { AppContext } from '../context';
import Calculator from '../components/Calculator';

interface IScenarioProps {
  scenarioKey: 'a' | 'b' | 'c'
}

const Scenario: React.FC<IScenarioProps> = ({scenarioKey}) => {

  const { state } = useContext(AppContext);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Scenario {scenarioKey.toUpperCase()}{state.restaurantName ? `: ${state.restaurantName}` : ''}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              Scenario {scenarioKey.toUpperCase()}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <Calculator scenarioKey={scenarioKey} />
      </IonContent>
    </IonPage>
  );
};

export default Scenario;
