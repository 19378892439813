import {
  IonList,
  IonItem,
  IonInput,
  IonLabel,
  IonButton,
  IonModal,
  IonContent,
  IonAccordion,
  IonAccordionGroup,
  IonRange,
  IonItemDivider,
  IonIcon,
  IonNote,
  IonText
} from '@ionic/react';
import { card, cardOutline, cash, cashOutline, logoUsd } from 'ionicons/icons';
import { useContext } from 'react';
import { AppContext } from '../context';
import { numberWithCommas, calcGTV, Types, monthlyProfit, takeAmount, calcCommissions } from '../reducers';
import './Calculator.css';

interface ICalcProps {
  scenarioKey: 'a' | 'b' | 'c'
}

const Calculator: React.FC<ICalcProps> = ({scenarioKey}) => {

  const { state, dispatch } = useContext(AppContext);
  const scenario = state[scenarioKey];
  (window as any).scenario = scenario;
  const dispatchKey: 'Update_A' | 'Update_B' | 'Update_C' = `Update_${scenarioKey.toUpperCase() as 'A' | 'B' | 'C'}`;

  const percentageRateFormatter = (value: number) => `${value.toFixed(2)}%`;
  const flatRateFormatter = (value: number) => `${(value * 100).toFixed()}¢`;
  const monthlyFeeFormatter = (value: number) => `$${value}`;

  return (
    <>
      <IonList>
        <IonAccordionGroup>
          <IonAccordion value='variables'>
            <IonItem slot="header">
              <IonLabel>Variables</IonLabel>
            </IonItem>
            <IonList slot="content">
              <IonItem>
                  <IonLabel position="floating">Hardware Cost</IonLabel>
                <IonInput
                  type="number"
                  value={scenario.hardwareCost}
                  onIonChange={e => {
                    if (e.detail.value) {
                      dispatch({
                        type: Types.Update_Scenario,
                        payload: {
                          key: scenarioKey,
                          scenario: {
                            ...scenario,
                            hardwareCost: parseFloat(e.detail.value)
                          }
                        }
                      })
                    }
                  }}
                  clearInput>
                </IonInput>
              </IonItem>

              <IonItemDivider>Percentage Rate: {scenario.percentageRate}%</IonItemDivider>
              <IonItem>
                <IonRange
                  value={scenario.percentageRate}
                  pin={true}
                  min={2.40}
                  max={4.00}
                  step={0.05}
                  snaps={true}
                  color="primary"
                  pinFormatter={percentageRateFormatter}
                  onIonChange={e => {
                    const percentageRate = parseFloat(parseFloat(e.detail.value.toString()).toFixed(2));
                    dispatch({
                      type: Types.Update_Scenario,
                      payload: {
                        key: scenarioKey,
                        scenario: {
                          ...scenario,
                          percentageRate: percentageRate
                        }
                      }
                    })
                  }}
                >
                  <IonIcon size="small" slot="start" icon={cardOutline} />
                  <IonIcon slot="end" icon={card} />
                </IonRange>
              </IonItem>

              <IonItemDivider>Per Charge Rate: {scenario.flatRate * 100} ¢</IonItemDivider>
              <IonItem>
                <IonRange
                  value={scenario.flatRate}
                  pin={true}
                  min={0.00}
                  max={0.30}
                  step={0.05}
                  snaps={true}
                  color="primary"
                  pinFormatter={flatRateFormatter}
                  onIonChange={e => {
                    const flatRate = parseFloat(parseFloat(e.detail.value.toString()).toFixed(2));
                    dispatch({
                      type: Types.Update_Scenario,
                      payload: {
                        key: scenarioKey,
                        scenario: {
                          ...scenario,
                        flatRate: flatRate
                        }
                      }
                    })
                  }}
                >
                  <IonIcon size="small" slot="start" icon={logoUsd} />
                  <IonIcon slot="end" icon={logoUsd} />
                </IonRange>
              </IonItem>

              <IonItemDivider>Monthly Software Fee: ${scenario.monthlyFee.toFixed(2)}</IonItemDivider>
              <IonItem>
                <IonRange
                  value={scenario.monthlyFee}
                  pin={true}
                  min={0}
                  max={299}
                  step={99}
                  snaps={true}
                  color="primary"
                  pinFormatter={monthlyFeeFormatter}
                  onIonChange={e => {
                    const monthlyFee = parseFloat(parseFloat(e.detail.value.toString()).toFixed(2));
                    dispatch({
                      type: Types.Update_Scenario,
                      payload: {
                        key: scenarioKey,
                        scenario: {
                          ...scenario,
                          monthlyFee: monthlyFee
                        }
                      }
                    })
                  }}
                >
                  <IonIcon size="small" slot="start" icon={cashOutline} />
                  <IonIcon slot="end" icon={cash} />
                </IonRange>
              </IonItem>
            </IonList>
          </IonAccordion>
          <IonAccordion value='Financials'>
            <IonItem slot="header">
              <IonLabel>Financials</IonLabel>
            </IonItem>
            <IonList slot="content">
              <IonItem>
                <IonLabel>
                  Credit GTV: ${numberWithCommas(calcGTV(state))}
                </IonLabel>
              </IonItem>

              <IonItem>
                <IonLabel>
                  Monthly Revenue: ${numberWithCommas(takeAmount(state, scenarioKey))}
                </IonLabel>
              </IonItem>

              <IonItem>
                <IonLabel>
                  Monthly Gross Profit: ${numberWithCommas(monthlyProfit(state, scenarioKey))}
                </IonLabel>
              </IonItem>

              <IonItem>
                <IonLabel>
                  <IonNote color='primary'>
                    Commission: ${numberWithCommas(scenario.totalCommission)}
                  </IonNote><br/>
                  <IonNote color='success'>Upfront: ${numberWithCommas(scenario.upfrontCommission)}</IonNote>
                </IonLabel>
                <IonLabel slot='end'>
                  <IonNote color='secondary'>GTV: ${numberWithCommas(scenario.gtvCommission)}</IonNote><br/>
                  <IonNote color='tertiary'>MRR: ${numberWithCommas(scenario.monthlyFeeCommission)}</IonNote>
                </IonLabel>
              </IonItem>

            </IonList>
          </IonAccordion>
        </IonAccordionGroup>

        <IonItem color={scenario.resultColor}>
          <IonLabel className='results'>
            ({scenario.paybackPeriod?.toFixed(2)} months)<br/>
            {scenario.resultColor === 'success' ? 'Go for it!' : (scenario.resultColor === 'warning' ? 'Talk to your manager!' : 'I mean, you can ask, but probably no.')}
          </IonLabel>
        </IonItem>

        <IonButton
          expand="full"
          fill="clear"
          onClick={() => {
            dispatch({
              type: Types.Update_Scenario,
              payload: {
                key: scenarioKey,
                scenario: {
                  ...scenario,
                showModal: true
                }
              }
            })
          }}
        >
          Show Calculations
        </IonButton>
      </IonList>
      <IonModal isOpen={scenario.showModal}>
        <IonContent>
          <IonList>
            {scenario.calculationString.split('\n').map((str, i) => (
              <IonItem key={i}>
                <p>
                  {str}
                </p>
              </IonItem>
            ))}
          </IonList>
          <IonButton
            expand='block'
            onClick={() => {
              dispatch({
                type: Types.Update_Scenario,
                payload: {
                  key: scenarioKey,
                  scenario: {
                    ...scenario,
                  showModal: false
                  }
                }
              })
            }
          }>
            Close
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
};

export default Calculator;
