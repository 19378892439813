import React, { createContext, useReducer, Dispatch } from 'react';
import { AppActions, appReducer, calcCommissions, calcPaybackPeriod, calcResultColor, getCalcString } from './reducers';

export interface IScenario {
  hardwareCost: number,
  percentageRate: number,
  flatRate: number,
  monthlyFee: number,
  resultColor: string,
  paybackPeriod: number | null,
  showModal: boolean,
  calculationString: string,
  monthlyFeeCommission: number,
  gtvCommission: number,
  totalCommission: number,
  upfrontCommission: number,
}

export interface IAppState {
  restaurantName: string,
  restaurantType: 'table-service' | 'quick-service' | 'food-truck',
  averageTicket: number,
  weeklyCustomers: number,
  creditPercentage: number,
  isContractor: boolean,
  a: IScenario,
  b: IScenario,
  c: IScenario,
}

export const initialState: IAppState = {
  restaurantName: '',
  restaurantType: 'quick-service',
  averageTicket: 15.00,
  weeklyCustomers: 500,
  creditPercentage: 90,
  isContractor: false,
  a: {
    hardwareCost: 750.00,
    percentageRate: 2.69,
    flatRate: 0.15,
    monthlyFee: 99.00,
    resultColor: 'light',
    paybackPeriod: null,
    showModal: false,
    calculationString: '',
    monthlyFeeCommission: 0,
    gtvCommission: 0,
    totalCommission: 0,
    upfrontCommission: 0,
  },
  b: {
    hardwareCost: 750.00,
    percentageRate: 2.69,
    flatRate: 0.15,
    monthlyFee: 99.00,
    resultColor: 'light',
    paybackPeriod: null,
    showModal: false,
    calculationString: '',
    monthlyFeeCommission: 0,
    gtvCommission: 0,
    totalCommission: 0,
    upfrontCommission: 0,
  },
  c: {
    hardwareCost: 750.00,
    percentageRate: 2.69,
    flatRate: 0.15,
    monthlyFee: 99.00,
    resultColor: 'light',
    paybackPeriod: null,
    showModal: false,
    calculationString: '',
    monthlyFeeCommission: 0,
    gtvCommission: 0,
    totalCommission: 0,
    upfrontCommission: 0,
  }
}

export type scenarioKey = 'a' | 'b' | 'c' 

export const setupComputedStateValues = (state: IAppState) => {
  const arr: scenarioKey[] = ['a', 'b', 'c'];
  arr.forEach(key => {
    const commissions = calcCommissions(state, key);
    state[key].calculationString    = getCalcString(state, key);
    state[key].paybackPeriod        = calcPaybackPeriod(state, key);
    state[key].resultColor          = calcResultColor(state, key);
    state[key].monthlyFeeCommission = commissions.monthlyFeeCommission;
    state[key].gtvCommission        = commissions.gtvCommission;
    state[key].totalCommission      = commissions.total;
    state[key].upfrontCommission    = commissions.upfront;
  })
  return state;
}

setupComputedStateValues(initialState);

const AppContext = createContext<{
  state: IAppState;
  dispatch: Dispatch<AppActions>;
}>({
  state: initialState,
  dispatch: () => null
});

const AppProvider: React.FC = ({ children }) => {
  const INITIAL_STATE: IAppState = JSON.parse(localStorage.getItem("state") as string) || initialState;

  const [state, dispatch] = useReducer(appReducer, INITIAL_STATE);

  return (
    <AppContext.Provider value={{state, dispatch}}>
      {children}
    </AppContext.Provider>
  )
}

export { AppProvider, AppContext };