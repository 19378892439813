import { IonAccordion, IonAccordionGroup, IonButton, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonPage, IonRange, IonSelect, IonSelectOption, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
import { thumbsDownOutline, thumbsUpOutline } from 'ionicons/icons';
import { useContext } from 'react';
import { AppContext } from '../context';
import { calcGTV, numberWithCommas, Types } from '../reducers';

const Setup: React.FC = () => {

  const { state, dispatch } = useContext(AppContext);
  const percentageRateFormatter = (value: number) => `${value}%`;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Setup</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Setup</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonList>
          <IonItem>
            <IonLabel position="floating">Restaurant Name</IonLabel>
            <IonInput
              type="text"
              value={state.restaurantName}
              onIonChange={e => {
                if (e.detail.value) {
                  dispatch({
                    type: Types.Update,
                    payload: {
                      ...state,
                      restaurantName: e.detail.value
                    }
                  })
                }
              }}
              clearInput>
            </IonInput>
          </IonItem>

          <IonItem>
            <IonLabel position="floating">Restaurant Type</IonLabel>
            <IonSelect
              value={state.restaurantType}
              placeholder="Select One"
              onIonChange={e => {
                dispatch({
                  type: Types.Update,
                  payload: {
                    ...state,
                    restaurantType: e.detail.value
                  }
                })
              }}
            >
              <IonSelectOption value="table-service">Table Service</IonSelectOption>
              <IonSelectOption value="quick-service">Quick Service</IonSelectOption>
              <IonSelectOption value="food-truck">Food Truck</IonSelectOption>
            </IonSelect>
          </IonItem>

          <IonItem>
            <IonLabel position="floating">Average Ticket</IonLabel>
            <IonInput
              type="number"
              value={state.averageTicket}
              onIonChange={e => {
                if (e.detail.value) {
                  dispatch({
                    type: Types.Update,
                    payload: {
                      ...state,
                      averageTicket: parseFloat(e.detail.value)
                    }
                  })
                }
              }}
              clearInput>
            </IonInput>
          </IonItem>

          <IonItem>
            <IonLabel position="floating">Weekly Customers</IonLabel>
            <IonInput
              type="number"
              value={state.weeklyCustomers}
              onIonChange={e => {
                if (e.detail.value) {
                  dispatch({
                    type: Types.Update,
                    payload: {
                      ...state,
                      weeklyCustomers: parseFloat(e.detail.value)
                    }
                  })
                }
              }}
              clearInput>
            </IonInput>
          </IonItem>

          <IonItemDivider>Credit Percentage: {state.creditPercentage}%</IonItemDivider>
            <IonItem>
              <IonRange
                value={state.creditPercentage}
                pin={true}
                min={0}
                max={100}
                step={5}
                snaps={true}
                color="primary"
                pinFormatter={percentageRateFormatter}
                onIonChange={e => {
                  const creditPercentage = parseFloat(parseFloat(e.detail.value.toString()).toFixed(2));
                  dispatch({
                    type: Types.Update,
                    payload: {
                      ...state,
                      creditPercentage: creditPercentage
                    }
                  })
                }}
              >
                <IonIcon size="small" slot="start" icon={thumbsDownOutline} />
                <IonIcon slot="end" icon={thumbsUpOutline} />
              </IonRange>
            </IonItem>

            <IonItem>
              <IonLabel>
                Credit GTV: ${numberWithCommas(calcGTV(state))}
              </IonLabel>
            </IonItem>
            
            <IonAccordionGroup>
              <IonAccordion value='other-variables'>
                <IonItem slot="header">
                  <IonLabel>Other Variables</IonLabel>
                </IonItem>
                <IonItem slot="content">
                  <IonLabel>
                    Are you a Contractor?
                  </IonLabel>
                  <IonToggle
                    checked={state.isContractor}
                    onIonChange={e => {
                      const isContractor = e.detail.checked;
                      dispatch({
                        type: Types.Update,
                        payload: {
                          ...state,
                          isContractor: isContractor,
                        }
                      })
                    }
                  }/>
                </IonItem>
              </IonAccordion>
            </IonAccordionGroup>

          <IonButton
            expand='full'
            color='danger'
            fill='clear'
            onClick={() => {
              dispatch({
                type: Types.Reset,
                payload: state,
              })
          }}>Reset Data</IonButton>
          
        </IonList>

      </IonContent>
    </IonPage>
  );
};

export default Setup;
